import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  pic: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    // marginTop: '1em',
    marginLeft: '20%',
    marginBottom: '3%',
    display: 'block',
  },
  about: {
    // marginTop: '1em',
  },
}));
