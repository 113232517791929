import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    whiteSpace: 'pre-line',
  },
  purple: {
    color: '#AF75FF',
  },
  red: {
    color: '#F54E4E',
  },
  yellow: {
    color: '#FABC37',
  },
  green: {
    color: '#b9db51',
  },
  white: {
    color: 'white',
  },
}));
